<script>
import { defineComponent } from 'vue'
import DefaultDrawerAction from '/~/components/drawer/components/actions/drawer-action.vue'

export default defineComponent({
  name: 'drawer-action-v2',
  extends: DefaultDrawerAction,
})
</script>

<template>
  <div
    v-clickaway="onClickaway"
    class="relative w-full overflow-hidden rounded-md"
  >
    <button
      class="w-full cursor-pointer text-left disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50"
      :disabled="isDisabled"
      @click="onClick"
    >
      <span class="flex items-start justify-between">
        <span
          class="mr-2.5 flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-gray-50 text-eonx-neutral-800 sm:h-12 sm:w-12"
        >
          <base-icon :svg="action.icon" :size="action.size || 'md'" />
        </span>
        <span class="block min-w-0 grow">
          <span class="block truncate font-bold leading-7">
            {{ action.label }}
          </span>
          <span
            class="block overflow-hidden break-words text-sm leading-tight text-eonx-neutral-600"
          >
            {{ action.description }}
          </span>
        </span>
      </span>
    </button>
    <transition
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="isConfirmVisible"
        class="absolute inset-0 flex h-full w-full transform items-center justify-between rounded-md transition duration-100 ease-out"
        :class="typeClass"
      >
        <span class="px-5 font-bold">
          {{ confirmText }}
        </span>
        <div
          v-if="!isProcessing"
          class="flex shrink-0 items-center space-x-2.5 pr-[15px]"
        >
          <button
            v-for="confirmAction in confirmActions"
            :key="confirmAction.label"
            class="flex h-10 w-10 items-center justify-center rounded-full bg-white"
            :class="[
              confirmAction.isSubmit ? 'text-fg-success' : 'text-fg-error',
            ]"
            @click="onConfirmAction(confirmAction)"
          >
            <base-icon :svg="confirmAction.icon" size="md" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
